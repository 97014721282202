import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Home } from "./pages/Home";
import { Works } from "./pages/Works";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <main className="main-homepage">
            <Navbar />
            <Outlet />
            <Footer />
          </main>
        </>
      ),
      children: [
        {
          index: true, // This will make the Home page the default route
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "works",
          element: <Works />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    AOS.refresh();
  }, [location]);

  return null;
}

export default App;
