import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const Works = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <section className="projects-area">
        <div className="container">
          <h1 className="section-heading" data-aos="fade-up">
            <img src="assets/images/star-2.png" alt="Star" /> All Projects{" "}
            <img src="assets/images/star-2.png" alt="Star" />
          </h1>
          <div className="row">
            <div className="col-md-4">
              <div data-aos="zoom-in">
                <div className="project-item shadow-box">
                  <a
                    className="overlay-link"
                    href="https://www.ytperfect.com/"
                  />
                  <img
                    src="assets/images/bg1.png"
                    alt="BG"
                    className="bg-img"
                  />
                  <div className="project-img">
                    <img src="assets/images/ytperfect.png" alt="Project" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="project-info">
                      <p>WEB Developement</p>
                      <h1>Yt Perfect</h1>
                    </div>
                    <a
                      href="https://www.ytperfect.com/"
                      className="project-btn"
                    >
                      <img src="assets/images/icon.svg" alt="Button" />
                    </a>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in">
                <div className="project-item shadow-box">
                  <a
                    className="overlay-link"
                    href="https://nike-landing-page-tan.vercel.app/"
                  />
                  <img
                    src="assets/images/bg1.png"
                    alt="BG"
                    className="bg-img"
                  />
                  <div className="project-img">
                    <img src="assets/images/project-3.jpg" alt="Project" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="project-info">
                      <p>Web Designing</p>
                      <h1>Nike Landing Page</h1>
                    </div>
                    <a
                      href="https://nike-landing-page-tan.vercel.app/"
                      className="project-btn"
                    >
                      <img src="assets/images/icon.svg" alt="Button" />
                    </a>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in" className="flex-1">
                <div className="project-item shadow-box">
                  <a
                    className="overlay-link"
                    href="https://yoom-two.vercel.app/"
                  />
                  <img
                    src="assets/images/bg1.png"
                    alt="BG"
                    className="bg-img"
                  />
                  <div className="project-img">
                    <img src="assets/images/yoom.png" alt="Project" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="project-info">
                      <p>Web Developement </p>
                      <h1>Yoom Meeting App</h1>
                    </div>
                    <a
                      href="https://yoom-two.vercel.app/"
                      className="project-btn"
                    >
                      <img src="assets/images/icon.svg" alt="Button" />
                    </a>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in" className="flex-1">
                <div className="project-item shadow-box">
                  <a
                    className="overlay-link"
                    href="https://ever-secure-notes-9xhs.vercel.app/"
                  />
                  <img
                    src="assets/images/bg1.png"
                    alt="BG"
                    className="bg-img"
                  />
                  <div className="project-img">
                    <img src="assets/images/122.png" alt="Project" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="project-info">
                      <p>Web Developement </p>
                      <h1>Ever Secure Notes</h1>
                    </div>
                    <a
                      href="https://ever-secure-notes-9xhs.vercel.app/"
                      className="project-btn"
                    >
                      <img src="assets/images/icon.svg" alt="Button" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <h1 className="section-heading" data-aos="fade-up">
                <img src="assets/images/star-2.png" alt="Star" /> All Projects{" "}
                <img src="assets/images/star-2.png" alt="Star" />
              </h1>
              <div className="d-flex align-items-start gap-24">
                <div data-aos="zoom-in" className="flex-1">
                  <div className="project-item shadow-box">
                    <a
                      className="overlay-link"
                      href="https://blitz-web.vercel.app/"
                    />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="project-img">
                      <img src="assets/images/blitz.png" alt="Project" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="project-info">
                        <p>Web Developement </p>
                        <h1>Blitz Social Media App</h1>
                      </div>
                      <a
                        href="https://blitz-web.vercel.app/"
                        className="project-btn"
                      >
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-in" className="flex-1">
                  <div className="project-item shadow-box">
                    <a
                      className="overlay-link"
                      href="https://www.slidescrafterz.com/"
                    />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="project-img">
                      <img src="assets/images/slides.png" alt="Project" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="project-info">
                        <p>Web Designing</p>
                        <h1>Slides Crafterz</h1>
                      </div>
                      <a
                        href="https://www.slidescrafterz.com/"
                        className="project-btn"
                      >
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-start gap-24">
                <div data-aos="zoom-in" className="flex-1">
                  <div className="project-item shadow-box">
                    <a
                      className="overlay-link"
                      href="https://rescuenergy.com/"
                    />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="project-img">
                      <img src="assets/images/rescu.png" alt="Project" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="project-info">
                        <p>Web Developement</p>
                        <h1>Rescuenergy</h1>
                      </div>
                      <a
                        href="https://rescuenergy.com/"
                        className="project-btn"
                      >
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-in" className="flex-1">
                  <div className="project-item shadow-box">
                    <a className="overlay-link" href="https://sumz.surge.sh/" />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="project-img">
                      <img src="assets/images/sumz.png" alt="Project" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="project-info">
                        <p>Web Developement</p>
                        <h1>Sumz</h1>
                      </div>
                      <a href="https://sumz.surge.sh/" className="project-btn">
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-start gap-24">
                <div data-aos="zoom-in" className="flex-1">
                  <div className="project-item shadow-box">
                    <a
                      className="overlay-link"
                      href="https://shorten-my-url.vercel.app/"
                    />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="project-img">
                      <img src="assets/images/tt.png" alt="Project" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="project-info">
                        <p>Web Developement </p>
                        <h1>Shorten My URL</h1>
                      </div>
                      <a
                        href="https://shorten-my-url.vercel.app/"
                        className="project-btn"
                      >
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-in" className="flex-1">
                  <div className="project-item shadow-box">
                    <a
                      className="overlay-link"
                      href="https://coming-soon-zgroup.vercel.app/"
                    />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="project-img">
                      <img src="assets/images/166.png" alt="Project" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="project-info">
                        <p>Web Designing</p>
                        <h1>Coming Soon Page</h1>
                      </div>
                      <a
                        href="https://coming-soon-zgroup.vercel.app/"
                        className="project-btn"
                      >
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
