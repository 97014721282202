import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* About */}
      <section className="about-area">
        <div className="container">
          <div className="d-flex about-me-wrap align-items-start gap-24">
            <div data-aos="zoom-in">
              <div className="about-image-box shadow-box">
                <img src="assets/images/bg1.png" alt="BG" className="bg-img" />
                <div className="image-inner">
                  <img src="assets/images/me4.png" alt="About Me" />
                </div>
              </div>
            </div>
            <div className="about-details" data-aos="zoom-in">
              <h1 className="section-heading" data-aos="fade-up">
                <img src="assets/images/star-2.png" alt="Star" />
                Self-summary <img src="assets/images/star-2.png" alt="Star" />
              </h1>
              <div className="about-details-inner shadow-box">
                <img src="assets/images/icon2.png" alt="Star" />
                <h1>Muhammad Osama</h1>
                <p>
                  I'm Osama, a passionate developer with expertise in PHP, HTML,
                  CSS, JavaScript, Bootstrap, Tailwind, React.js, TypeScript,
                  and Node.js. With 3 years of experience, I specialize in
                  creating responsive, user-friendly websites and scalable web
                  applications. I also have a strong background in
                  development,and in sales ensuring that my digital solutions
                  align with business goals. <br /> "Developing websites by day,
                  gaming universe by night."
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-24">
            <div className="col-md-6" data-aos="zoom-in">
              <div className="about-edc-exp about-experience shadow-box">
                <img src="assets/images/bg1.png" alt="BG" className="bg-img" />
                <h3>EXPERIENCE</h3>
                <ul>
                  <li>
                    <p className="date">2023 - Present</p>
                    <h2>React js Developer</h2>
                    <p className="type">TheHexa Town</p>
                  </li>
                  <li>
                    <p className="date">2021 - 2023</p>
                    <h2>Full-Stack Developer</h2>
                    <p className="type">Ecommaliance</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6" data-aos="zoom-in">
              <div className="about-edc-exp about-education shadow-box">
                <img src="assets/images/bg1.png" alt="BG" className="bg-img" />
                <h3>EDUCATION</h3>
                <ul>
                  <li>
                    <p className="date">2023</p>
                    <h2>Meta Front-End Developer</h2>
                    <p className="type">Coursera</p>
                  </li>
                  <li>
                    <p className="date">2021 - 2025</p>
                    <h2>Fazaia Degree Collage PAF Base Faisal
                      &amp; The University Of Lahore
                      <p />
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-24">
            <div className="col-md-12">
              <div className="d-flex profile-contact-credentials-wrap gap-24">
                <div data-aos="zoom-in">
                  <div className="about-profile-box info-box shadow-box h-full">
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="inner-profile-icons shadow-box">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/aliaalam404/"
                      >
                        <i className="fa-brands fa-instagram" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/aliaalam404/"
                      >
                        <i className="fa-brands fa-facebook" />
                      </a>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="infos">
                        <h4>Stay with me</h4>
                        {/* <h1>Profiles</h1> */}
                      </div>
                      <a href="contact.html" className="about-btn">
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-in" className="flex-1">
                  <div className="about-contact-box info-box shadow-box">
                    <a className="overlay-link" href="contact.html" />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <img
                      src="assets/images/icon2.png"
                      alt="Icon"
                      className="star-icon"
                    />
                    <h1>
                      Let's <br />
                      work <span>together.</span>
                    </h1>
                    <a href="contact.html" className="about-btn">
                      <img src="assets/images/icon.svg" alt="Button" />
                    </a>
                  </div>
                </div>
                <div data-aos="zoom-in" className="h-full">
                  <div className="about-crenditials-box info-box shadow-box">
                    <a className="overlay-link" href="credentials.html" />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <img src="assets/images/sign.png"  alt="Sign" />
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="infos">
                        <h4>more about me</h4>
                        <h1>Credentials</h1>
                      </div>
                      <a href="credentials.html" className="about-btn">
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
