import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Ensure Font Awesome CSS is loaded
import RandomIconChanger from "./RandomIconChanger";


export const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);



  return (
    <>
      <section className="about-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6" data-aos="zoom-in">
              <div className="about-me-box shadow-box">
                <a className="overlay-link" href="about.html" />
                <img src="assets/images/bg1.png" alt="BG" className="bg-img" />
                <div className="img-box">
                  <img src="assets/images/me5.png" alt="About Me" />
                </div>
                <div className="infos">
                  <h4>WEB Developer</h4>
                  <h1>M Osama</h1>
                  <p>
                    I am a skilled Website Designer & Developer with expertise
                    in a wide range of technologies.
                    <br /> React.js | HTML | CSS | JavaScript | JQuery Bootstrap
                    | Tailwind | PHP | Node.js | Flutter Power BI | React Native
                    | SQL
                  </p>
                  <a href="#" className="about-btn">
                    <img src="assets/images/icon.svg" alt="Button" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-credentials-wrap">
                <div data-aos="zoom-in">
                  <div className="banner shadow-box">
                    <div className="marquee">
                      <div>
                        <span>
                          Currently Work At <b>TheHexa Town</b>{" "}
                          <img src="assets/images/star1.svg" alt="Star" /> Ex
                          From <b>Eccomalliance</b>{" "}
                          <img src="assets/images/star1.svg" alt="Star" />{" "}
                          Internee From <b>TheMentors</b>{" "}
                          <img src="assets/images/star1.svg" alt="Star" />
                          Freelancer on <b>Upwork</b>{" "}
                          <img src="assets/images/star1.svg" alt="Star" />{" "}
                          Freelancer on <b>Fiverr</b>{" "}
                          <img src="assets/images/star1.svg" alt="Star" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gx-row d-flex gap-24">
                  <div data-aos="zoom-in">
                    <div className="about-crenditials-box info-box shadow-box h-full">
                      <a className="overlay-link" href="credentials.html" />
                      <img
                        src="assets/images/bg1.png"
                        alt="BG"
                        className="bg-img"
                      />
                      <img src="assets/images/sign.png" alt="Sign" />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="infos">
                          <h4>More about me</h4>
                          <h1>Credentials</h1>
                        </div>
                        <a href="credentials.html" className="about-btn">
                          <img src="assets/images/icon.svg" alt="Button" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div data-aos="zoom-in">
                    <div className="about-project-box info-box shadow-box h-full">
                      <a className="overlay-link" href="works.html" />
                      <img
                        src="assets/images/bg1.png"
                        alt="BG"
                        className="bg-img"
                      />
                      <img src="assets/images/my-works.png" alt="My Works" />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="infos">
                          <h4>SHOWCASE</h4>
                          <h1>Projects</h1>
                        </div>
                        <a href="#" className="about-btn">
                          <img src="assets/images/icon.svg" alt="Button" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-24">
            <div className="col-md-12">
              <div className="blog-service-profile-wrap d-flex gap-24">
                <div data-aos="zoom-in">
                  <div
                    className="about-blog-box info-box shadow-box h-full"
                    id="socialProfile"
                  >
                    <a href="#" className="overlay-link" id="profileLink" />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="social-media-icons">
                      <RandomIconChanger />{" "}
                      {/* Here we include the random icon changer */}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="infos">
                        <h4>Profile</h4>
                        <h1 id="profileName">Muahmmad Osama</h1>
                      </div>
                      <a href="#" className="about-btn" id="profileLink">
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-in" className="flex-1">
                  <div className="about-services-box info-box shadow-box h-full">
                    <link to="work" className="overlay-link" />
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="icon-boxes">
                      <i className="fa-solid fa-magnifying-glass" />
                      <i className="fa-solid fa-paintbrush" />
                      <i className="fa-solid fa-border-none" />
                      <i className="fa-solid fa-code" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="infos">
                        <h4>specialization</h4>
                        <h1>Services Offering</h1>
                      </div>
                      <a href="service.html" className="about-btn">
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-in">
                  <div className="about-profile-box info-box shadow-box h-full">
                    <img
                      src="assets/images/bg1.png"
                      alt="BG"
                      className="bg-img"
                    />
                    <div className="inner-profile-icons shadow-box">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/osama_unoff/profilecard/?igsh=cm82Z2Z1ejdjNHVv"
                      >
                        <i class="fa-brands fa-instagram" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/muhammad-osama-947a21282/"
                      >
                        <i className="fa-brands fa-linkedin" />
                      </a>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="infos">
                        <h4>Stay with me</h4>
                        <h1>Profiles</h1>
                      </div>
                      <a href="contact.html" className="about-btn">
                        <img src="assets/images/icon.svg" alt="Button" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-24">
            <div className="col-md-6" data-aos="zoom-in">
              <div className="about-client-box info-box shadow-box">
                <img src="assets/images/bg1.png" alt="BG" className="bg-img" />
                <div className="clients d-flex align-items-start gap-24 justify-content-center">
                  <div className="client-item">
                    <h1>03</h1>
                    <p>
                      Years <br />
                      Experience
                    </p>
                  </div>
                  <div className="client-item">
                    <h1>+12</h1>
                    <p>
                      CLIENTS <br />
                      WORLDWIDE
                    </p>
                  </div>
                  <div className="client-item">
                    <h1>+71</h1>
                    <p>
                      Total <br />
                      Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6" data-aos="zoom-in">
              <div className="about-contact-box info-box shadow-box">
                <a className="overlay-link" href="contact.html" />
                <img src="assets/images/bg1.png" alt="BG" className="bg-img" />
                <img
                  src="assets/images/icon2.png"
                  alt="Icon"
                  className="star-icon"
                />
                <h1>
                  Let's <br />
                  work <span>together.</span>
                </h1>
                <a href="#" className="about-btn">
                  <img src="assets/images/icon.svg" alt="Button" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
