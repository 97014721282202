import React from "react";
import { NavLink } from "react-router-dom";

export const Navbar = () => {
  return (
    <>
      <header className="header-area">
        <div className="container">
          <div className="gx-row d-flex align-items-center justify-content-between">
            <NavLink to="/" className="logo">
              <img src="assets/images/logo.png" alt="Logo" />
            </NavLink>
            <nav className="navbar">
              <ul className="menu">
                <li>
                  <NavLink
                    to="/"
                    style={({ isActive }) => ({
                      color: isActive ? "#008080" : "inherit",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                    end // Ensure exact match for home route
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about"
                    style={({ isActive }) => ({
                      color: isActive ? "#008080" : "inherit",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/works"
                    style={({ isActive }) => ({
                      color: isActive ? "#008080" : "inherit",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                  >
                    Works
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    style={({ isActive }) => ({
                      color: isActive ? "#008080" : "inherit",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
              <NavLink to="/contact" className="theme-btn">
                Let's talk
              </NavLink>
            </nav>
            <div className="show-menu">
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
