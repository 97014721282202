import React from 'react'
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <>
    <footer className="footer-area">
  <div className="container">
    <div className="footer-content text-center">
      <NavLink to="home" className="logo">
        <img src="assets/images/logo.png" alt="Logo" />
      </NavLink>
      <ul className="footer-menu">
        <li><NavLink to="home">Home</NavLink></li>
        <li><NavLink to="about">About</NavLink></li>
        <li><NavLink to="works">Works</NavLink></li>
        <li><NavLink to="contact">Contact</NavLink></li>
      </ul>
      <p className="copyright">
        © All rights reserved by <span>Muhammad Osama</span>
      </p>
    </div>
  </div>
</footer>

    </>
  )
}
