import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Ensure FontAwesome is loaded

const RandomIconChanger = () => {
  // List of possible icons, their corresponding links, and their logo colors
  const socialMedia = [
    {
      name: "Instagram",
      iconClass: "fa-brands fa-instagram",
      link: "https://www.instagram.com/osama_unoff/profilecard/?igsh=cm82Z2Z1ejdjNHVv",
      color: "#E1306C", // Instagram color
    },
    {
      name: "Snapchat",
      iconClass: "fa-brands fa-snapchat",
      link: "https://www.snapchat.com",
      color: "#FFFC00", // Snapchat color
    },
    {
      name: "Snapchat",
      iconClass: "fa-brands fa-linkedin",
      link: "https://www.linkedin.com/in/muhammad-osama-947a21282/",
      color: "#0077B5 ", // Snapchat color
    },
    {
      name: "Facebook",
      iconClass: "fa-brands fa-facebook",
      link: "https://www.facebook.com",
      color: "#1877F2", // Facebook color
    },
    {
      name: "GitHub",
      iconClass: "fa-brands fa-github",
      link: "https://github.com",
      color: "#fff", // GitHub color
    },
  ];

  // State to store the current icon and its link
  const [currentIcon, setCurrentIcon] = useState({});

  // Randomly select an icon, its corresponding link, and color
  const getRandomIcon = () => {
    const randomIndex = Math.floor(Math.random() * socialMedia.length);
    return socialMedia[randomIndex];
  };

  useEffect(() => {
    // Set the random icon, link, and color on component mount (page refresh)
    setCurrentIcon(getRandomIcon());
  }, []); // Empty array ensures this effect runs only once on page load

  return (
    <a href={currentIcon.link} target="_blank" rel="noopener noreferrer">
      <i
        className={currentIcon.iconClass} // Dynamically set the class to the random icon
        style={{
          fontSize: "130px",
          display: "block", // Use block to allow centering
          marginLeft: "auto", // Center the icon horizontally
          marginRight: "auto", // Center the icon horizontally
          textAlign: "center", // Optional: center the icon if it's part of a larger block
          color: currentIcon.color, // Set the icon color dynamically based on the social media
        }}
      ></i>
    </a>
  );
};

export default RandomIconChanger;
