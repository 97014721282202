import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; 

export const Contact = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []); 

  return (
    <>
      {/* Contact */}
      <section className="contact-area">
        <div className="container">
          <div className="gx-row d-flex justify-content-between gap-24">
            <div className="contact-infos">
              <h3 data-aos="fade-up">Contact Info</h3>
              <ul className="contact-details">
                <li className="d-flex align-items-center" data-aos="zoom-in">
                  <div
                    className="icon-box shadow-box"
                    style={{
                      display: "flex", // To ensure the icon is centered
                      justifyContent: "center", // Center the icon horizontally
                      alignItems: "center", // Center the icon vertically
                    }}
                  >
                    <i class="fa-regular fa-envelope" />
                  </div>
                  <div className="right">
                    <span>MAIL Me</span>
                    <h4>sheikhusama569@gmail.com</h4>
                  </div>
                </li>
                <li className="d-flex align-items-center" data-aos="zoom-in">
                  <div
                    className="icon-box shadow-box"
                    style={{
                      display: "flex", // To ensure the icon is centered
                      justifyContent: "center", // Center the icon horizontally
                      alignItems: "center", // Center the icon vertically
                    }}
                  >
                    <i class="fa-solid fa-phone" />
                  </div>
                  <div className="right">
                    <span>Contact Us</span>
                    <h4>+92-300-0297461</h4>
                  </div>
                </li>
                <li className="d-flex align-items-center" data-aos="zoom-in">
                  <div
                    className="icon-box shadow-box"
                    style={{
                      display: "flex", // To ensure the icon is centered
                      justifyContent: "center", // Center the icon horizontally
                      alignItems: "center", // Center the icon vertically
                    }}
                  >
                    <i className="fa-solid fa-location-dot" />
                  </div>
                  <div className="right">
                    <span>Location</span>
                    <h4>
                      Punjab <br />
                      Pakistan <br />
                    </h4>
                  </div>
                </li>
              </ul>
              <h3 data-aos="fade-up">Social Info</h3>
              <ul
                className="social-links d-flex align-center"
                data-aos="zoom-in"
              >
                <li>
                  <a
                    className="shadow-box"
                    style={{
                      display: "flex", // To ensure the icon is centered
                      justifyContent: "center", // Center the icon horizontally
                      alignItems: "center", // Center the icon vertically
                    }}
                    href="https://www.linkedin.com/in/muhammad-osama-947a21282/"
                  >
                    <i className="fa-brands fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    className="shadow-box "
                    style={{
                      display: "flex", // To ensure the icon is centered
                      justifyContent: "center", // Center the icon horizontally
                      alignItems: "center", // Center the icon vertically
                    }}
                    href=""
                  >
                    <i className="fa-brands fa-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    className="shadow-box"
                    style={{
                      display: "flex", // To ensure the icon is centered
                      justifyContent: "center", // Center the icon horizontally
                      alignItems: "center", // Center the icon vertically
                    }}
                    href="https://www.instagram.com/osama_unoff/profilecard/?igsh=cm82Z2Z1ejdjNHVv"
                  >
                    <i className="fa-brands fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            <div data-aos="zoom-in" className="contact-form">
              <div className="shadow-box">
                <img src="assets/images/bg1.png" alt="BG" className="bg-img" />
                <img src="assets/images/icon3.png" alt="Icon" />
                <h1>
                  Let’s work <span>together.</span>
                </h1>
                <form method="POST" data-form>
                  <div
                    className="alert alert-succeiss messenger-box-contact__msg"
                    style={{ display: "none" }}
                    role="alert"
                  >
                    Your message was sent successfully.
                  </div>
                  <div className="input-group">
                    <input
                      required
                      type="text"
                      name="name"
                      placeholder="Name *"
                    />
                  </div>
                  <div className="input-group">
                    <input
                      required
                      type="email"
                      name="email"
                      placeholder="Email *"
                    />
                  </div>
                  <div className="input-group">
                    <input
                      required
                      type="text"
                      name="subject"
                      placeholder="Your Subject *"
                    />
                  </div>
                  <div className="input-group">
                    <textarea
                      required
                      name="message"
                      placeholder="Your Message *"
                      defaultValue={""}
                    />
                  </div>
                  <div className="input-group">
                    <button
                      type="submit"
                      data-form-btn
                      className="theme-btn submit-btn"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
